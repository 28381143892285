<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                リッチメッセージ
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                リッチメッセージの新規作成
              </div>
            </div>
            <div class="modal-header_cancell" @click="onClickCancel">
              <img class="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </section>
          <section class="rich_area">
            <div @change="setUploadImage($event.target.files[0])">
              <form enctype="multipart/form-data" novalidate>
                <label class="btn btn-availability">
                  <input
                    id="file_upload"
                    type="file"
                    name="file"
                    class="input-file"
                    accept="image/gif, image/jpeg, image/jpg, image/png"
                  />
                  <img class="icon" src="@/assets/img/add_white.svg" alt />
                  画像を選択する
                </label>
              </form>
            </div>
            <div class="rich_main">
              <div v-if="form.data.fileInfo" class="rich_image">
                <div
                  v-show="form.data.checkShareType == 1"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share1_a"
                    :href="form.data.richMessageLink.typeA"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                </div>
                <div
                  v-show="form.data.checkShareType == 2"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share2_a"
                    :href="form.data.richMessageLink.typeA"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                  <a
                    class="rich_share_item share2_b"
                    :href="form.data.richMessageLink.typeB"
                    target="_blank"
                  >
                    <span>B</span>
                  </a>
                </div>
                <div
                  v-show="form.data.checkShareType == 3"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share3_a"
                    :href="form.data.richMessageLink.typeA"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                  <a
                    class="rich_share_item share3_b"
                    :href="form.data.richMessageLink.typeB"
                    target="_blank"
                  >
                    <span>B</span>
                  </a>
                  <a
                    class="rich_share_item share3_c"
                    :href="form.data.richMessageLink.typeC"
                    target="_blank"
                  >
                    <span>C</span>
                  </a>
                </div>
                <div
                  v-show="form.data.checkShareType == 4"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share4_a"
                    :href="form.data.richMessageLink.typeA"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                  <a
                    class="rich_share_item share4_b"
                    :href="form.data.richMessageLink.typeB"
                    target="_blank"
                  >
                    <span>B</span>
                  </a>
                  <a
                    class="rich_share_item share4_c"
                    :href="form.data.richMessageLink.typeC"
                    target="_blank"
                  >
                    <span>C</span>
                  </a>
                  <a
                    class="rich_share_item share4_d"
                    :href="form.data.richMessageLink.typeD"
                    target="_blank"
                  >
                    <span>D</span>
                  </a>
                </div>
                <img
                  class="rich_image_bind"
                  :src="thumbnailBase64 ? thumbnailBase64 : ''"
                  width="478"
                  height="478"
                  alt
                />
              </div>
              <div v-else class="rich_image">
                <form
                  enctype="multipart/form-data"
                  class="form-fileupload"
                  novalidate
                >
                  <label for="file_upload-box">
                    <input
                      id="file_upload-box"
                      type="file"
                      name="file"
                      class="input-file"
                      accept="image/gif, image/jpeg, image/jpg, image/png"
                      @change="setUploadImage($event.target.files[0])"
                    />
                    1040px × 1040px の画像を選択する
                  </label>
                </form>
              </div>
              <div class="rich_share">
                <p class="rich_heading">リッチメッセージのリンク分割方法</p>
                <ul>
                  <li :class="form.data.checkShareType == 1 ? 'checked' : ''">
                    <label for="share01">
                      <input
                        id="share01"
                        v-model="form.data.checkShareType"
                        type="radio"
                        name="share"
                        value="1"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_01.png"
                        width="60"
                        height="60"
                        alt
                      />
                      <p>リンクを分割しない</p>
                    </label>
                  </li>
                  <li :class="form.data.checkShareType == 2 ? 'checked' : ''">
                    <label for="share02">
                      <input
                        id="share02"
                        v-model="form.data.checkShareType"
                        type="radio"
                        name="share"
                        value="2"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_02.png"
                        width="60"
                        height="60"
                        alt
                      />
                      <p>リンクを2つに分割する</p>
                    </label>
                  </li>
                  <li :class="form.data.checkShareType == 3 ? 'checked' : ''">
                    <label for="share03">
                      <input
                        id="share03"
                        v-model="form.data.checkShareType"
                        type="radio"
                        name="share"
                        value="3"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_03.png"
                        width="60"
                        height="60"
                        alt
                      />
                      <p>リンクを3つに分割する</p>
                    </label>
                  </li>
                  <li :class="form.data.checkShareType == 4 ? 'checked' : ''">
                    <label for="share04">
                      <input
                        id="share04"
                        v-model="form.data.checkShareType"
                        type="radio"
                        name="share"
                        value="4"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_04.png"
                        width="60"
                        height="60"
                        alt
                      />
                      <p>リンクを4つに分割する</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="rich_bottom">
              <p class="rich_heading">タイトル</p>
              <p class="rich_heading-addition">管理用のタイトル名です</p>
              <div class="input_tr">
                <input
                  v-model="form.data.richMessageTitle"
                  type="text"
                  maxlength="50"
                  placeholder="管理用タイトルを入力してください"
                  class="richmessage_name input_block"
                  :class="{
                    'is-danger': errors.richMessageTitle.isValid !== true,
                  }"
                  @input="
                    validateOnInput(
                      'richMessageTitle',
                      form.data.richMessageTitle,
                      validateRequire,
                    )
                  "
                />
                <span
                  v-show="errors.richMessageTitle.isValid !== true"
                  class="fa-warning font-s error_message"
                >
                  {{ errors.richMessageTitle.message }}
                </span>
              </div>
              <p class="rich_heading">スマートフォンロック画面メッセージ</p>
              <p class="rich_heading-addition">
                スマートフォンのロック画面時に表示されます
              </p>
              <div class="input_tr">
                <div class="line_alt_text-inputarea">
                  <input
                    v-model="form.data.richMessageAltText"
                    type="text"
                    maxlength="400"
                    placeholder="ロック画面メッセージを入力して下さい"
                    class="richmessage_alt_text input_block"
                    :class="{
                      'is-danger': errors.richMessageAltText.isValid !== true,
                    }"
                    @input="
                      validateOnInput(
                        'richMessageAltText',
                        form.data.richMessageAltText,
                        validateRequire,
                      )
                    "
                  />
                  <span
                    v-show="errors.richMessageAltText.isValid !== true"
                    class="fa-warning font-s error_message"
                  >
                    {{ errors.richMessageAltText.message }}
                  </span>
                </div>
              </div>
              <p class="rich_heading">メッセージ確認</p>
              <div class="input_tr">
                <input
                  v-model="form.data.richMessageCheck"
                  type="checkbox"
                  class="setting_input"
                />
                <span>あり</span>
              </div>
              <p class="rich_heading">メッセージ</p>
              <p class="rich_heading-addition">
                リッチメッセージの前に表示されます
              </p>
              <div class="input_tr">
                <div class="linechat_block_input-area_header">
                  <div class="emoji-picker-wrapper">
                    <emoji-picker :is-outlined="true" @selected="appendEmoji" />
                  </div>
                </div>
                <textarea
                  v-model="form.data.richMessagePush"
                  maxlength="1000"
                  cols="30"
                  rows="10"
                  class="richmessage_push_textarea"
                  placeholder="メッセージを入力してください（最大1000文字）"
                />
              </div>
              <p class="rich_heading">
                リッチメッセージのリンクを設定してください。
              </p>
              <p class="rich_heading-addition">
                「https://」「line://」のいずれかから始まるURLを入力してください
              </p>
              <ul class="link-list">
                <li class="item">
                  <div class="row">
                    <p class="link-label -linkA">リンクA</p>
                    <input
                      v-model="form.data.richMessageLink.typeA"
                      type="text"
                      maxlength="400"
                      placeholder="https://example.co.jp/"
                      class="input_block"
                      :class="{
                        'is-danger': errors.richMessageLinkA.isValid !== true,
                      }"
                      @input="
                        validateOnInput(
                          'richMessageLinkA',
                          form.data.richMessageLink.typeA,
                          validateRichMessageUrl,
                        )
                      "
                    />
                  </div>
                  <p
                    v-show="errors.richMessageLinkA.isValid !== true"
                    class="fa-warning font-s error_message"
                  >
                    {{ errors.richMessageLinkA.message }}
                  </p>
                  <p class="url">
                    <a target="_blank" :href="form.data.richMessageLink.typeA">
                      {{ form.data.richMessageLink.typeA }}
                    </a>
                  </p>
                </li>
                <li v-if="form.data.checkShareType != 1" class="item">
                  <div class="row">
                    <p class="link-label -linkB">リンクB</p>
                    <input
                      v-model="form.data.richMessageLink.typeB"
                      type="text"
                      maxlength="400"
                      placeholder="https://example.co.jp/"
                      class="input_block"
                      :class="{
                        'is-danger': errors.richMessageLinkB.isValid !== true,
                      }"
                      @input="
                        validateOnInput(
                          'richMessageLinkB',
                          form.data.richMessageLink.typeB,
                          validateRichMessageUrl,
                        )
                      "
                    />
                  </div>
                  <p
                    v-show="errors.richMessageLinkB.isValid !== true"
                    class="fa-warning font-s error_message"
                  >
                    {{ errors.richMessageLinkB.message }}
                  </p>
                  <p class="url">
                    <a target="_blank" :href="form.data.richMessageLink.typeB">
                      {{ form.data.richMessageLink.typeB }}
                    </a>
                  </p>
                </li>
                <li
                  v-if="
                    form.data.checkShareType != 1 &&
                    form.data.checkShareType != 2
                  "
                  class="item"
                >
                  <div class="row">
                    <p class="link-label -linkC">リンクC</p>
                    <input
                      v-model="form.data.richMessageLink.typeC"
                      type="text"
                      maxlength="400"
                      placeholder="https://example.co.jp/"
                      class="input_block"
                      :class="{
                        'is-danger': errors.richMessageLinkC.isValid !== true,
                      }"
                      @input="
                        validateOnInput(
                          'richMessageLinkC',
                          form.data.richMessageLink.typeC,
                          validateRichMessageUrl,
                        )
                      "
                    />
                  </div>
                  <p
                    v-show="errors.richMessageLinkC.isValid !== true"
                    class="fa-warning font-s error_message"
                  >
                    {{ errors.richMessageLinkC.message }}
                  </p>
                  <p class="url">
                    <a target="_blank" :href="form.data.richMessageLink.typeC">
                      {{ form.data.richMessageLink.typeC }}
                    </a>
                  </p>
                </li>
                <li v-if="form.data.checkShareType == 4" class="item">
                  <div class="row">
                    <p class="link-label -linkD">リンクD</p>
                    <input
                      v-model="form.data.richMessageLink.typeD"
                      type="text"
                      maxlength="400"
                      placeholder="https://example.co.jp/"
                      class="input_block"
                      :class="{
                        'is-danger': errors.richMessageLinkD.isValid !== true,
                      }"
                      @input="
                        validateOnInput(
                          'richMessageLinkD',
                          form.data.richMessageLink.typeD,
                          validateRichMessageUrl,
                        )
                      "
                    />
                  </div>
                  <p
                    v-show="errors.richMessageLinkD.isValid !== true"
                    class="fa-warning font-s error_message"
                  >
                    {{ errors.richMessageLinkD.message }}
                  </p>
                  <p class="url">
                    <a target="_blank" :href="form.data.richMessageLink.typeD">
                      {{ form.data.richMessageLink.typeD }}
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </section>
          <div class="btn-col2">
            <div class="btn btn-unavailable" @click="onClickCancel">
              キャンセル
            </div>
            <button-base
              class="btn submit-button"
              button-text="リッチメッセージを保存"
              :is-loading="isLoading"
              @click="registerRichMessage"
            />
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import useValidation from '@/composables/useValidation';
import EmojiPicker from '@/components/ui/menus/components/EmojiPicker';
import Modal from '@/components/ui/modals/components/Modal';

import RichMessageService from '@/services/richmessage';
import { validateFile } from '@/utils/file';

export default defineComponent({
  name: 'ModalRegisterRichMessage',
  components: { EmojiPicker, Modal },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'visibleNotificationAfterOnReloadRichMessageRegister',
    'onClickCancel',
  ],
  setup(props, context) {
    const CONVERT_SIZES = [1040, 700, 460, 300, 240];
    const {
      errors,
      resetErrors,
      validateRequire,
      validateRichMessageUrl,
      validateOnInput,
    } = useValidation([
      'richMessageTitle',
      'richMessageAltText',
      'richMessageLinkA',
      'richMessageLinkB',
      'richMessageLinkC',
      'richMessageLinkD',
    ]);
    const store = useStore();
    const isLoading = ref(false);
    const thumbnailBase64 = ref('');
    const form = reactive({
      data: {
        fileInfo: null,
        checkShareType: 1,
        richMessageTitle: '',
        richMessageAltText: '',
        richMessagePush: '',
        richMessageLink: { typeA: '', typeB: '', typeC: '', typeD: '' },
        richMessageCheck: false,
        richMessageBase64Images: [],
      },
    });

    // watch
    watch(
      () => props.isVisible,
      () => {
        form.data.fileInfo = null;
      },
    );
    // methods
    const registerRichMessage = async () => {
      if (form.data.richMessageBase64Images.length !== 5) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '画像ファイルをアップロードしてください',
          type: false,
        });
        return;
      }
      if (validateAll() !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容を確認してください',
          type: false,
        });
        return;
      }
      isLoading.value = true;
      const res = await RichMessageService.createRichMessage({
        name: form.data.richMessageTitle,
        push_message:
          form.data.richMessagePush === null ? '' : form.data.richMessagePush,
        line_alt_text: form.data.richMessageAltText,
        images: form.data.richMessageBase64Images,
        link_a: form.data.richMessageLink.typeA,
        link_b:
          form.data.checkShareType >= 2
            ? form.data.richMessageLink.typeB
            : null,
        link_c:
          form.data.checkShareType >= 3
            ? form.data.richMessageLink.typeC
            : null,
        link_d:
          form.data.checkShareType >= 4
            ? form.data.richMessageLink.typeD
            : null,
        staff_id: store.getters['staff/staff'].id,
        image_type: form.data.checkShareType,
        richmessage_check: form.data.richMessageCheck,
      });
      if (res.success !== true) {
        isLoading.value = false;
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        return;
      }
      context.emit('visibleNotificationAfterOnReloadRichMessageRegister', {
        message: res.message,
        type: res.success,
      });
      reset();
    };
    const setUploadImage = async file => {
      thumbnailBase64.value = '';
      const imgSizeValid = await validateImageSize(file);
      if (imgSizeValid !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '画像ファイルは、1040px ☓ 1040px をご用意ください',
          type: false,
        });
        form.data.fileInfo = null;
        return;
      }
      const newFileInfo = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModifiedDate: file.lastModifiedDate,
        file,
      };
      const validationResult = validateFile(newFileInfo);
      if (validationResult.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validationResult.message,
          type: false,
        });
        form.data.fileInfo = null;
      }
      form.data.fileInfo = newFileInfo;
      const resizeBase64images = [];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        for (const pxSize of CONVERT_SIZES) {
          const img = new Image();
          img.crossOrigin = 'Anonymous';
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const canvas = document.createElement('canvas');
            const afterWidth = pxSize;
            const afterHeight = pxSize;
            canvas.width = afterWidth;
            canvas.height = afterHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
              img,
              0,
              0,
              width,
              height,
              0,
              0,
              afterWidth,
              afterHeight,
            );
            resizeBase64images.push(canvas.toDataURL());
            form.data.richMessageBase64Images = resizeBase64images;
          };
          img.src = e.target.result;
        }
        thumbnailBase64.value = e.target.result;
      };
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: 'ファイルを選択しました。',
        type: true,
      });
    };
    const validateAll = () => {
      resetErrors();
      const isValidTitle = validateRequire(
        'richMessageTitle',
        form.data.richMessageTitle,
      );
      const isValidAltText = validateRequire(
        'richMessageAltText',
        form.data.richMessageAltText,
      );
      const isValidLinkA = validateRichMessageUrl(
        'richMessageLinkA',
        form.data.richMessageLink.typeA,
      );
      const isValidLinkB = validateRichMessageUrl(
        'richMessageLinkB',
        form.data.richMessageLink.typeB,
        form.data.checkShareType > 1,
      );
      const isValidLinkC = validateRichMessageUrl(
        'richMessageLinkC',
        form.data.richMessageLink.typeC,
        form.data.checkShareType > 2,
      );
      const isValidLinkD = validateRichMessageUrl(
        'richMessageLinkD',
        form.data.richMessageLink.typeD,
        form.data.checkShareType > 3,
      );
      return (
        isValidTitle === true &&
        isValidAltText === true &&
        isValidLinkA === true &&
        isValidLinkB === true &&
        isValidLinkC === true &&
        isValidLinkD === true
      );
    };
    const validateImageSize = file => {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = e => {
          const img = new Image();
          img.crossOrigin = 'Anonymous';
          img.onload = () => {
            if (img.width === 1040 && img.height === 1040) {
              resolve(true);
            } else {
              resolve(false);
            }
          };
          img.src = e.target.result;
        };
      });
    };
    const reset = () => {
      form.data = {
        fileInfo: null,
        checkShareType: 1,
        richMessageTitle: '',
        richMessageAltText: '',
        richMessagePush: '',
        richMessageLink: { typeA: '', typeB: '', typeC: '', typeD: '' },
        richMessageCheck: false,
        richMessageBase64Images: [],
      };
    };
    const onClickCancel = () => {
      reset();
      context.emit('onClickCancel');
    };
    const appendEmoji = emoji => {
      const textArea = document.querySelector('.richmessage_push_textarea');
      const beforeText = textArea.value.substring(0, textArea.selectionStart);
      const afterText = textArea.value.substring(
        textArea.selectionStart,
        textArea.value.length,
      );
      form.data.richMessagePush = `${beforeText}${emoji}${afterText}`;
    };
    const resetSubmitButton = () => {
      // 親コンポーネントで呼び出されているので注意
      isLoading.value = false;
    };

    return {
      errors,
      validateRequire,
      validateRichMessageUrl,
      validateOnInput,
      isLoading,
      thumbnailBase64,
      form,
      registerRichMessage,
      setUploadImage,
      onClickCancel,
      appendEmoji,
      resetSubmitButton,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.setting_input {
  appearance: none;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.3rem;
  border-radius: 0.4rem;
  border: 2px solid #9d9d9d;
  background: #fff;
  position: relative;
  cursor: pointer;
  &:checked {
    &::after {
      content: url('../../../../assets/img/check.svg');
      height: 1.1rem;
      width: 1.5rem;
      margin: -5px 0 0 1px;
      position: absolute;
      bottom: 3px;
      left: 0;
    }
  }
}
.content {
  @include full_modal;
  overflow-y: auto;
  .modal-body {
    padding-bottom: 40px;
    & :deep(.area-table) {
      margin: 2rem 0 0;
    }
  }
  .modal-body_btn_item.disabled {
    cursor: default;
  }
}

.body {
  padding: 0 20px;
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.rich_area {
  max-width: 1200px;
  padding: 30px;
  margin: 80px auto 0;
  text-align: left;
  background-color: $white;
}
@media (max-width: ($media_query_sp)) {
  .rich_area {
    padding: 20px;
  }
}

.rich_main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0 30px;
}
@media (max-width: ($media_query_sp)) {
  .rich_main {
    display: block;
  }
}

.rich_heading {
  font-size: 1.3rem;
  font-weight: bold;
  color: $mochica_color;
  padding-bottom: 1rem;
  text-align: left;
}
.rich_heading-addition {
  font-size: 0.9rem;
  padding-bottom: 1.5rem;
}
.input_tr {
  margin-bottom: 30px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }
}
.input-file {
  display: none;
}

.rich_image {
  position: relative;
  width: 40%;
  height: 100%;
}
@media (max-width: ($media_query_sp)) {
  .rich_image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}
.rich_image-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rich_image img {
  width: 100%;
  height: 100%;
}

.rich_share {
  width: 58%;
  ul {
    & > li {
      text-align: left;
    }
  }
}
@media (max-width: ($media_query_sp)) {
  .rich_share {
    width: 100%;
  }
}
.rich_share_item {
  position: absolute;
  display: table;
}

.rich_share_item span {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 5rem;
  color: #757575;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'alpha(opacity=100)';
}

.rich_image .share1_a {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.rich_image .share2_a {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
}
.rich_image .share2_b {
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
}
.rich_image .share3_a {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
}
.rich_image .share3_b {
  width: 50%;
  height: 50%;
  bottom: 0;
  left: 0;
  border-right: 1px solid #ccc;
}
.rich_image .share3_c {
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
}
.rich_image .share4_a {
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.rich_image .share4_b {
  width: 50%;
  height: 50%;
  top: 0;
  right: 0;
}
.rich_image .share4_c {
  width: 50%;
  height: 50%;
  bottom: 0;
}
.rich_image .share4_d {
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.rich_share {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}
.rich_share .rich_heading {
  padding: 25px;
}
.rich_share li {
  border-top: 1px solid #f0f0f0;
}
.rich_share li.checked {
  background: #efefef;
}
.rich_share li label {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
}
.rich_share li input,
.rich_share li img {
  margin-right: 15px;
}
@media (max-width: ($media_query_sp)) {
  .rich_share li img {
    width: 30px;
    height: auto;
  }
}
.richmessage_name {
  width: 100%;
  max-width: 650px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.rich_bottom {
  text-align: left;
}

.link-list {
  .item + .item {
    margin-top: 20px;
  }
  .row {
    display: flex;
  }
  .input_block {
    width: 550px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
  }
  .fa-warning {
    padding-left: 80px;
  }
  .url {
    padding: 10px 0 0 80px;
    word-break: break-all;
  }
}
@media (max-width: ($media_query_sp)) {
  .link-list {
    .row {
      display: block;
    }
    .input_block {
      width: 100%;
      margin-top: 10px;
    }
    .fa-warning {
      padding-left: 0;
    }
    .url {
      padding: 10px 0 0 0;
    }
  }
}

.link-label {
  white-space: nowrap;
  margin-right: 20px;
  padding: 8px;
  font-weight: bold;
}
@media (max-width: ($media_query_sp)) {
  .link-label {
    margin-right: 0;
  }
}
.link-label.-linkA {
  background: #f7f7f7;
  border: 1px solid #d8d8d8;
}
.link-label.-linkB {
  background: #e1f3fa;
  border: 1px solid #90cfef;
}
.link-label.-linkC {
  background: #faf5e1;
  border: 1px solid #edc98e;
}
.link-label.-linkD {
  background: #edfae0;
  border: 1px solid #90ee94;
}

.rich_table p {
  font-size: 1.2rem;
  margin: 20px 0 0 14px;
  color: $mochica_color;
}
.rich_table a {
  color: $mochica_color;
}
.line_alt_text-inputarea {
  margin-bottom: 2rem;
}
.richmessage_check {
  display: inline;
}
.richmessage_alt_text {
  width: 100%;
  max-width: 650px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.richmessage_push_textarea {
  background: $white;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  line-height: 1.8;
  min-height: 150px;
}

.form-fileupload {
  height: 429px;
}
@media (max-width: ($media_query_sp)) {
  .form-fileupload {
    height: 150px;
  }
}

label[for='file_upload-box'] {
  @include flex_center_center;
  height: 100%;
  border: 2px dashed #d6d6d6;
  color: dimgray;
  padding: 20px;
  position: relative;
  line-height: 1.3;
  text-align: center;
  cursor: pointer;
}

.emoji-picker-wrapper {
  margin-bottom: 7px;
}

.btn-col2 {
  max-width: 1200px;
  margin: 20px auto;
  .submit-button {
    padding: 15px 10px;
  }
}
@include validation_effects;
</style>
